import { signIn } from "next-auth/react"

export default async function signInToAzure(
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  username?: string,
  redirect?: string
) {
  if (!username || username.length === 0) return
  setIsLoading(true)
  const options = {} as Record<string, any>

  if (
    username.endsWith("@pars.doe.gov") ||
    username.endsWith("@doepars.com") ||
    username.endsWith("@parspm.onmicrosoft.com")
  ) {
    options.login_hint = username
  } else {
    const response = await fetch(`/api/user/hint?username=${username}`)
    const json = await response.json()
    const fullusername = json.upn
    if (username) {
      options.login_hint = fullusername
    }
  }
  if (redirect) {
    options.callbackUrl = redirect
  }
  signIn("microsoft-entra-id", undefined, options)

  setIsLoading(false)
}
